import { ButtonComponent } from "../../button";
import { Colors, IconStyle, Size, Variant } from "../../button/dto";
import { setFindDocumentStatus } from "../../documents/slice";
import { EmptyList } from "../../emptyList/emptyList";
import { HeadingSize } from "../../heading/dto";
import { LayoutComponent } from "../../layout";
import { NewObjectService } from "../../objects/service";
import { findFileById, findMultipleFileById, setFindMultipleFileById } from "../../objects/slice";
import { PaginationComponent } from "../../pagination";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { MenuItems } from "../../sidebar/dto";
import { downloadZip } from "../../utils";
import { findAllFiscalDocumentsEmployee, findByIdFiscalDocument, findByIdFiscalDocuments, setFiscalDocumentEmployeeFilterPage } from "../slice";
import { EmployeeList } from "./employeeList";


export function EmployeeView() {
    const fiscalDocumentState = useAppSelector(state => state.fiscalDocument)
    const authState = useAppSelector(state => state.auth)

    const dispatch = useAppDispatch()

    const objectService = NewObjectService()

    return (
        <LayoutComponent
            headingLabel={"Gestione dipendenti"}
            headingButtons={[
                <>
                    {
                        fiscalDocumentState.downloadFiscalDocuments.length !== 0 &&
                        <ButtonComponent
                            key={'accounting-area-documents-sent-button'}
                            label={"Scarica"}
                            onClick={() => {
                                if (fiscalDocumentState.downloadFiscalDocuments.length === 1) {
                                    dispatch(findFileById(fiscalDocumentState.downloadFiscalDocuments.find(id => id.objectId)?.objectId!)).then((e) => {
                                        //@ts-ignore
                                        objectService.downloadBase64WithExtension(fiscalDocumentState.downloadFiscalDocuments.find(id => id.name)?.name, e.payload !== null ? e.payload : '')
                                        dispatch(findByIdFiscalDocument(fiscalDocumentState.downloadFiscalDocuments.find(id => id.id)?.id!))
                                            .then(() => {
                                                dispatch(setFindDocumentStatus('idle'))
                                                dispatch(findAllFiscalDocumentsEmployee({ ...fiscalDocumentState.fiscalDocumentEmployeeFilters, companyId: authState.findMeResponse !== undefined ? authState.findMeResponse.id.toString() : '' }))
                                            })
                                    });
                                } else {
                                    dispatch(findMultipleFileById(fiscalDocumentState.downloadFiscalDocuments.map(doc => doc.objectId))).then((e) => {
                                        //@ts-ignore
                                        downloadZip(e.payload !== '' ? e.payload : '', fiscalDocumentState.downloadFiscalDocuments.map(name => name.name), authState.findMeResponse?.businessName + "-Dipendenti")
                                        dispatch(findByIdFiscalDocuments(fiscalDocumentState.downloadFiscalDocuments.map(id => id.id)))
                                            .then(() => {
                                                dispatch(setFindMultipleFileById("idle"))
                                                dispatch(findAllFiscalDocumentsEmployee({ ...fiscalDocumentState.fiscalDocumentEmployeeFilters, companyId: authState.findMeResponse !== undefined ? authState.findMeResponse.id.toString() : '' }))
                                            })
                                    })
                                }
                            }}
                            color={Colors.PRIMARY}
                            variant={Variant.OUTLINE}
                            size={Size.MD}
                            iconStyle={IconStyle.OFF}
                        />
                    }
                </>
            ]}
            breadcrumbItems={["Gestione dipendenti"]}
            headingSize={HeadingSize.LG}
            menuItem={MenuItems.EMPLOYEE}
        >
            <div style={{ marginTop: '16px' }}>
                <EmployeeList />
            </div>
            {
                fiscalDocumentState.findAllFiscalDocumentEmployeeResponse !== undefined &&
                fiscalDocumentState.findAllFiscalDocumentEmployeeResponse.total > 0 &&
                <PaginationComponent
                    page={fiscalDocumentState.findAllFiscalDocumentEmployeeResponse !== undefined ? fiscalDocumentState.findAllFiscalDocumentEmployeeResponse.page : 0}
                    count={fiscalDocumentState.findAllFiscalDocumentEmployeeResponse !== undefined ? fiscalDocumentState.findAllFiscalDocumentEmployeeResponse.totalPage : 0}
                    onChange={(page) => dispatch(setFiscalDocumentEmployeeFilterPage(page))}
                />
            }
            {
                fiscalDocumentState.findAllFiscalDocumentEmployeeResponse !== undefined &&
                fiscalDocumentState.findAllFiscalDocumentEmployeeResponse.total === 0 &&
                <EmptyList />
            }
        </LayoutComponent>
    )
}