import JSZip from "jszip"
import { NewObjectService } from "../objects/service"

export type PromiseStatuses = 'idle' | 'loading' | 'successfully' | 'failed'
export type OnButtonClick = React.MouseEventHandler<HTMLButtonElement> | undefined
export type OnMenuItemClick = React.MouseEventHandler<HTMLLIElement> | undefined
export type OnChange = React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
export type OnKeyDown = React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
export type Operation = 'save' | 'edit'
export const BACKEND_HOST = 'http://localhost:8080'
export type QuarkusError = { message: string }[]

export async function toBase64(file: File): Promise<string> {
    const bytes = new Uint8Array(await file.arrayBuffer());

    let binary = '';

    for (let i = 0; i < bytes.byteLength; i++) {

        binary += String.fromCharCode(bytes[i]);

    }
    return btoa(binary);
}

export function renderBase64Img(img: string): string {
    return 'data:image/png;base64,' + img;
}

export const downloadZip = async (base64: string[], fileName: string[], zipName?: string) => {
    const zip = new JSZip();
    const objectService = NewObjectService()

    for (let i = 0; i < base64.length; i++) {
        const name = fileName[i]
        const fileFromBase64 = objectService.base64ToFile(base64[i])
        zip.file(name, fileFromBase64)
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
        objectService.download(zipName ?? "Documenti", content)
    });
};
