import { useEffect } from 'react';
import './App.css';
import { findAllActivityType, findReceivedDocsActivity, findSentDocsActivity } from './activity/slice';
import { findFinancialAdvisor, findFinancialAdvisorAvatar, findMe } from './auth/slice';
import { getCompanyUnseenInfoMessages, groupUnSeenMessages } from './chatV2/slice';
import { findAllAtecos, findAllReceivedDocuments, findAllSentDocuments, getCompanyUnseenInfoDocuments } from './documents/slice';
import { setCompanyIdFilter, setNewEventCompanyId } from './events/slice';
import { findAllFiscalDocumentsBalance, findAllFiscalDocumentsEmployee, findAllFiscalDocumentsF24, findAllFiscalDocumentsOthers, findUnseenFiscalDocuments } from './fiscalDocument/slice';
import { keycloak } from './keycloak';
import { setKeycloakInitStatus, setSocket, urlMap } from './keycloak/slice';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { KeycloakRouter } from './router';
import { SpinnerComponent } from './spinner';

function App() {
  const dispatch = useAppDispatch()

  const keycloakState = useAppSelector(state => state.secured)
  const authState = useAppSelector(state => state.auth)
  const documentsState = useAppSelector(state => state.document)
  const activityState = useAppSelector(state => state.activity)
  const chatState = useAppSelector(state => state.chat)
  const fiscalDocumentState = useAppSelector(state => state.fiscalDocument)

  keycloak.onAuthSuccess = () => {
    if (!keycloak.hasRealmRole('company')) {
      keycloak.logout({ redirectUri: window.location.origin })
    }
    dispatch(setSocket(new WebSocket(urlMap.get(window.location.origin) + "/chat", ["access_token", keycloak.token || ''])))
    dispatch(getCompanyUnseenInfoDocuments())
    dispatch(setKeycloakInitStatus('successfully'))
    dispatch(findMe())
    dispatch(findAllAtecos())
  }

  useEffect(() => {
    if (authState.findMeStatus === 'successfully' && authState.findMeResponse !== undefined) {
      dispatch(getCompanyUnseenInfoMessages(authState.findMeResponse.id.toString()))
      dispatch(groupUnSeenMessages(authState.findMeResponse.id.toString()))
      dispatch(findFinancialAdvisor(authState.findMeResponse.financialAdvisorId))
      dispatch(findAllReceivedDocuments({ companyId: authState.findMeResponse.id, year: documentsState.documentReceivedFilters.year, period: documentsState.documentReceivedFilters.period, page: documentsState.documentReceivedFilters.page }))
      dispatch(findAllActivityType({ name: '', activityCategoryId: '' }))
      dispatch(setCompanyIdFilter(authState.findMeResponse.id))
      dispatch(findAllSentDocuments({ companyId: authState.findMeResponse.id, period: documentsState.documentSentFilters.period, year: documentsState.documentSentFilters.year, page: documentsState.documentSentFilters.page }))

      if (authState.findMeResponse.premium) {
        dispatch(findAllFiscalDocumentsF24({ ...fiscalDocumentState.fiscalDocumentF24Filters, companyId: authState.findMeResponse?.id.toString() }))
        dispatch(findAllFiscalDocumentsEmployee({ ...fiscalDocumentState.fiscalDocumentEmployeeFilters, companyId: authState.findMeResponse?.id.toString() }))
        dispatch(findAllFiscalDocumentsBalance({ ...fiscalDocumentState.fiscalDocumentBalanceFilters, companyId: authState.findMeResponse?.id.toString() }))
        dispatch(findAllFiscalDocumentsOthers({ ...fiscalDocumentState.fiscalDocumentOthersFilters, companyId: authState.findMeResponse?.id.toString() }))
        dispatch(setNewEventCompanyId(authState.findMeResponse.id))
        dispatch(findUnseenFiscalDocuments(authState.findMeResponse.id.toString()))
      }
    }
  }, [authState.findMeStatus, authState.findMeResponse])

  useEffect(() => {
    if (authState.findFinancialAdvisorStatus === 'successfully' && authState.findFinancialAdvisorResponse !== undefined) {
      dispatch(findFinancialAdvisorAvatar(authState.findFinancialAdvisorResponse.avatarObjectId))
    }
  }, [authState.findFinancialAdvisorStatus])

  useEffect(() => {
    if (
      documentsState.findAllReceivedDocumentsStatus === 'successfully' &&
      documentsState.findAllRecivedDocumentsResponse !== undefined &&
      documentsState.findAllRecivedDocumentsResponse.data !== undefined &&
      documentsState.findAllRecivedDocumentsResponse.data.length > 0
    ) {
      const receivedActivityId = documentsState.findAllRecivedDocumentsResponse.data.filter(doc => doc.userId !== keycloak.subject).map(doc => doc.activity.id)
      dispatch(findReceivedDocsActivity(receivedActivityId))
    }
  }, [documentsState.findAllReceivedDocumentsStatus])

  useEffect(() => {
    if (
      documentsState.findAllSentDocumentsStatus === 'successfully' &&
      documentsState.findAllSentDocumentsResponse !== undefined &&
      documentsState.findAllSentDocumentsResponse.data !== undefined &&
      documentsState.findAllSentDocumentsResponse.data.length > 0
    ) {
      const sentActivityId = documentsState.findAllSentDocumentsResponse.data.filter(doc => doc.userId === keycloak.subject).map(doc => doc.activity.id)
      dispatch(findSentDocsActivity(sentActivityId))
    }
  }, [documentsState.findAllSentDocumentsStatus])

  keycloak.onAuthRefreshError = () => {
    keycloak.logout()
  }

  keycloak.onAuthRefreshSuccess = () => {
    keycloakState.socket?.close()
    dispatch(setSocket(new WebSocket(urlMap.get(window.location.origin) + "/chat", ["access_token", keycloak.token || ''])))
  }

  if (
    keycloakState.keycloakInitStatus === 'idle' ||
    authState.findMeStatus === 'loading' ||
    authState.findAvatarStatus === 'loading' ||
    authState.findFinancialAdvisorStatus === 'loading' ||
    authState.findFinancialAdvisorAvatarStatus === 'loading' ||
    activityState.findReceivedDocsActivitiesStatus === 'loading' ||
    activityState.findSentDocsActivitiesStatus === 'loading' ||
    documentsState.findAllAtecosStatus === 'loading' ||
    documentsState.companiesUnseenDocumentsStatus === 'loading' ||
    chatState.companyUnseenMessagesStatus === 'loading' ||
    fiscalDocumentState.findUnseenFiscalDocumentsStatus === 'loading'
  ) {
    return (<SpinnerComponent fullScreen size={'medium'} />)
  }

  return (
    <div className="App">
      <KeycloakRouter />
    </div>
  );
}

export default App;