import { MenuItem, TableBody } from "@mui/material";
import { format } from "date-fns";
import { ReactNode } from "react";
import { ButtonComponent } from "../button";
import { Colors, IconStyle, Size, Variant } from "../button/dto";
import { CheckboxComponent } from "../checkbox";
import { CheckboxSize, CheckboxStyle } from "../checkbox/dto";
import { HeadingComponent } from "../heading";
import { HeadingSize } from "../heading/dto";
import { DownloadIcon } from "../icons/download";
import { InfoTextPopup } from "../infoTextPopup";
import { NewObjectService } from "../objects/service";
import { findFileById, findMultipleFileById, setFindMultipleFileById } from "../objects/slice";
import { PaginationComponent } from "../pagination";
import { PillComponent } from "../pills";
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from "../pills/dto";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SelectComponent } from "../select";
import { SpinnerComponent } from "../spinner";
import { TableComponent } from "../table";
import { TableCellComponent } from "../table/tableCell";
import { TableHeadComponent } from "../table/tableHead";
import { TableRowComponent } from "../table/tableRow";
import colors from '../utils/index.module.scss';
import { AccountingAreaDocumentsModal } from "./accountingAreaDocumentsModal";
import { PeriodicityEnum } from "./dto";
import { findAllReceivedDocuments, findAllSentDocuments, findDocumentById, findDocumentsById, setDocumentReceivedFilterPage, setDocumentReceivedFilterPeriod, setDocumentReceivedFilterYear, setDownloadDocuments, setFindDocumentStatus } from "./slice";

export function DocumentReceived() {
    const dispatch = useAppDispatch()

    const companyState = useAppSelector(state => state.document)
    const authState = useAppSelector(state => state.auth)

    const objectService = NewObjectService()

    if (
        companyState.findAllReceivedDocumentsStatus === 'loading' ||
        authState.findMeStatus === 'loading' ||
        companyState.findCompanyStatus === 'loading' ||
        companyState.deleteDocumentStatus === 'loading'
    ) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                <SpinnerComponent size={"small"} />
            </div>
        )
    }

    let receivedRows: ReactNode[] = []

    const monthlyMap = new Map<number, string>([
        [0, '01'],
        [1, '02'],
        [2, '03'],
        [3, '04'],
        [4, '05'],
        [5, '06'],
        [6, '07'],
        [7, '08'],
        [8, '09'],
        [9, '10'],
        [10, '11'],
        [11, '12'],
    ])

    const quarterlyMap = new Map<number, string>([
        [0, 'I trimestre'],
        [1, 'II trimestre'],
        [2, 'III trimestre'],
        [3, 'IV trimestre'],
    ])

    if (
        companyState.findAllReceivedDocumentsStatus === 'successfully' &&
        companyState.findAllRecivedDocumentsResponse !== undefined &&
        companyState.findAllRecivedDocumentsResponse.data !== undefined &&
        companyState.findAllRecivedDocumentsResponse.data.length > 0 &&
        authState.findMeResponse !== undefined &&
        authState.findMeStatus === 'successfully'
    ) {
        companyState.findAllRecivedDocumentsResponse.data
            .forEach(data => {
                const companiesReceiverDate = data.companiesDownloadDate

                receivedRows.push(
                    <TableRowComponent key={'received-documents-' + data.id}>
                        <TableCellComponent
                            content={
                                <CheckboxComponent
                                    size={CheckboxSize.SM}
                                    style={CheckboxStyle.SQUARE}
                                    checked={companyState.downloadDocuments.find(doc => doc.objectId.toString() === data.objectId.toString()) !== undefined}
                                    onChange={(_, checked) => {
                                        if (checked) {
                                            dispatch(setDownloadDocuments([...companyState.downloadDocuments, { id: data.id, name: data.fileName, objectId: data.objectId.toString() }]))
                                        } else {
                                            const remove = companyState.downloadDocuments.filter(_doc => _doc.objectId.toString() !== data.objectId.toString())
                                            dispatch(setDownloadDocuments(remove))
                                        }
                                    }}
                                />
                            }
                            cellType={"row-regular"}
                            alignLabel={"right"}
                        />
                        <TableCellComponent label={data.fileName} cellType={"row-medium"} alignLabel={"left"} />
                        <TableCellComponent label={data.note.length <= 40 ? data.note : data.note.substring(0, 40).concat("...")} cellType={"row-regular"} alignLabel={"left"} />
                        <TableCellComponent content={<PillComponent label={data.activity.activityType.name} size={PillsSize.SM} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} />} cellType={"row-regular"} alignLabel={"left"} />
                        <TableCellComponent label={(authState.findMeResponse?.periodicity === PeriodicityEnum.Monthly ? monthlyMap.get(data.period) : quarterlyMap.get(data.period)) + '/' + data.year.toString()} cellType={"row-regular"} alignLabel={"left"} />
                        <TableCellComponent label={data.date && format(new Date(data.date), 'dd/MM/yyyy')} cellType={"row-regular"} alignLabel={"left"} />
                        <TableCellComponent label={companiesReceiverDate !== null ? '' : '-'} cellType={"row-regular"} content={companiesReceiverDate !== null ? <PillComponent label={format(new Date(companiesReceiverDate), 'dd/MM/yyyy')} size={PillsSize.SM} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.FALSE} /> : ''} alignLabel={"left"} />
                        <TableCellComponent
                            content={
                                <div className="d-flex justify-content-end align-items-center w-100">
                                    <InfoTextPopup
                                        label={"Scarica"}
                                        position="bottom-left"
                                        children={
                                            <ButtonComponent
                                                icon={<DownloadIcon colorBase={colors.neutral600} />}
                                                onClick={() => {
                                                    dispatch(findFileById(data.objectId.toString())).then((e) => {
                                                        //@ts-ignore
                                                        objectService.downloadBase64WithExtension(data.fileName, e.payload !== null ? e.payload : '')
                                                        dispatch(findDocumentById(data.id.toString()))
                                                            .then(() => {
                                                                dispatch(setFindDocumentStatus('idle'))
                                                                dispatch(findAllReceivedDocuments({ companyId: authState.findMeResponse !== undefined ? authState.findMeResponse.id : 0, period: companyState.documentReceivedFilters.period, year: companyState.documentReceivedFilters.year, page: companyState.documentReceivedFilters.page }))
                                                                dispatch(findAllSentDocuments({ companyId: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id : 0, period: companyState.documentSentFilters.period, year: companyState.documentSentFilters.year, page: companyState.documentSentFilters.page }))
                                                            })
                                                    });
                                                }}
                                                color={Colors.NEUTRAL}
                                                label=""
                                                variant={Variant.LINK}
                                                size={Size.SM}
                                                iconStyle={IconStyle.ONLY}
                                            />
                                        }
                                    />
                                </div>
                            }
                            cellType={"row-regular"}
                            alignLabel={"right"}
                        />
                    </TableRowComponent>
                )
            })
    }

    const startYear = 2022
    let yearsMenuItems: ReactNode[] = []

    for (let year = startYear; year <= new Date().getFullYear() + 1; year++) {
        yearsMenuItems.push(<MenuItem key={year} value={year}>{year}</MenuItem>)
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "flex-start",
            alignItems: 'flex-start',
            gap: '40px',
            width: '100%'
        }}>
            <div style={{ width: '100%', gap: '20px', display: 'flex', flexDirection: 'column' }}>
                <HeadingComponent
                    label={"Documenti ricevuti"}
                    buttons={[
                        <>
                            {
                                companyState.downloadDocuments.length !== 0 &&
                                <ButtonComponent
                                    key={'accounting-area-documents-sent-button'}
                                    label={"Scarica"}
                                    onClick={() => {
                                        if (companyState.downloadDocuments.length === 1) {
                                            dispatch(findFileById(companyState.downloadDocuments.find(id => id.objectId)?.objectId!)).then((e) => {
                                                //@ts-ignore
                                                objectService.downloadBase64WithExtension(companyState.downloadDocuments.find(id => id.name)?.name, e.payload !== null ? e.payload : '')
                                                dispatch(findDocumentById(companyState.downloadDocuments.find(id => id.id)?.id!))
                                                    .then(() => {
                                                        dispatch(setFindDocumentStatus('idle'))
                                                        dispatch(findAllReceivedDocuments({ companyId: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id : 0, period: companyState.documentSentFilters.period, year: companyState.documentSentFilters.year, page: companyState.documentSentFilters.page }))
                                                    })
                                            });
                                        } else {
                                            dispatch(findMultipleFileById(companyState.downloadDocuments.map(doc => doc.objectId))).then((e) => {
                                                //@ts-ignore
                                                downloadZip(e.payload !== '' ? e.payload : '', companyState.downloadDocuments.map(name => name.name), companyState.findCompanyResponse?.businessName + "-DocumentiContabili")
                                                dispatch(findDocumentsById(companyState.downloadDocuments.map(id => id.id)))
                                                    .then(() => {
                                                        dispatch(setFindMultipleFileById("idle"))
                                                        dispatch(findAllReceivedDocuments({ companyId: companyState.findCompanyResponse !== undefined ? companyState.findCompanyResponse.id : 0, period: companyState.documentSentFilters.period, year: companyState.documentSentFilters.year, page: companyState.documentSentFilters.page }))
                                                    })
                                            })
                                        }
                                    }}
                                    color={Colors.PRIMARY}
                                    variant={Variant.OUTLINE}
                                    size={Size.SM}
                                    iconStyle={IconStyle.OFF}
                                />
                            }
                        </>
                    ]}
                    size={HeadingSize.MD}
                />
                <div style={{ marginTop: '8px', gap: '16px' }} className="row m-0 p-0`">
                    <div className="col-12 col-lg p-0 m-0">
                        <SelectComponent
                            value={companyState.documentReceivedFilters.period.toString()}
                            onChange={(e) => {
                                dispatch(setDocumentReceivedFilterPeriod(e.target.value))
                                dispatch(findAllReceivedDocuments({
                                    companyId: authState.findMeResponse !== undefined ? authState.findMeResponse.id : 0,
                                    year: companyState.documentReceivedFilters.year,
                                    period: e.target.value as '' | number,
                                    page: companyState.documentReceivedFilters.page
                                }))
                            }}
                            id={"received-document-period"}
                            menuItems={
                                [
                                    <MenuItem key='document-received-period-filter' value=''>Seleziona mese</MenuItem>,
                                    <MenuItem key='document-received-period-filter-0' value={0}>Gennaio</MenuItem>,
                                    <MenuItem key='document-received-period-filter-1' value={1}>Febbraio</MenuItem>,
                                    <MenuItem key='document-received-period-filter-2' value={2}>Marzo</MenuItem>,
                                    <MenuItem key='document-received-period-filter-3' value={3}>Aprile</MenuItem>,
                                    <MenuItem key='document-received-period-filter-4' value={4}>Maggio</MenuItem>,
                                    <MenuItem key='document-received-period-filter-5' value={5}>Giugno</MenuItem>,
                                    <MenuItem key='document-received-period-filter-6' value={6}>Luglio</MenuItem>,
                                    <MenuItem key='document-received-period-filter-7' value={7}>Agosto</MenuItem>,
                                    <MenuItem key='document-received-period-filter-8' value={8}>Settembre</MenuItem>,
                                    <MenuItem key='document-received-period-filter-9' value={9}>Ottobre</MenuItem>,
                                    <MenuItem key='document-received-period-filter-10' value={10}>Novembre</MenuItem>,
                                    <MenuItem key='document-received-period-filter-11' value={11}>Dicembre</MenuItem>,
                                ]
                            } />
                    </div>
                    <div className="col-12 col-lg p-0 m-0">
                        <SelectComponent
                            id={"received-document-year"}
                            value={companyState.documentReceivedFilters.year.toString()}
                            onChange={(e) => {
                                dispatch(setDocumentReceivedFilterYear(e.target.value))
                                dispatch(findAllReceivedDocuments({
                                    companyId: authState.findMeResponse !== undefined ? authState.findMeResponse.id : 0,
                                    year: Number(e.target.value),
                                    period: companyState.documentReceivedFilters.period,
                                    page: companyState.documentReceivedFilters.page
                                }))
                            }}
                            menuItems={yearsMenuItems}
                        />
                    </div>
                    <div className="col-12 col-lg-auto p-0 m-0 d-flex align-items-center justify-content-center">
                        <div className="row p-0 m-0" style={{ gap: '8px' }}>
                            <div className="col p-0 m-0">
                                <ButtonComponent
                                    label={"Pulisci"}
                                    onClick={() => {
                                        dispatch(setDocumentReceivedFilterPeriod(''))
                                        dispatch(setDocumentReceivedFilterYear(new Date().getFullYear()))
                                        if (authState.findMeResponse)
                                            dispatch(findAllReceivedDocuments({ companyId: authState.findMeResponse.id, period: '', year: new Date().getFullYear(), page: companyState.documentReceivedFilters.page }))
                                    }}
                                    color={Colors.NEUTRAL}
                                    variant={Variant.OUTLINE}
                                    size={Size.MD}
                                    iconStyle={IconStyle.OFF}
                                />
                            </div>
                            <div className="col p-0 m-0">
                                <ButtonComponent
                                    label={"Applica"}
                                    onClick={() => {
                                        if (authState.findMeResponse)
                                            dispatch(findAllReceivedDocuments({ companyId: authState.findMeResponse.id, year: companyState.documentReceivedFilters.year, period: companyState.documentReceivedFilters.period, page: companyState.documentReceivedFilters.page }))
                                    }}
                                    color={Colors.PRIMARY} variant={Variant.OUTLINE} size={Size.MD} iconStyle={IconStyle.OFF} />
                            </div>
                        </div>
                    </div>
                </div>
                <TableComponent>
                    <TableHeadComponent>
                        <TableCellComponent
                            cellType={"head"}
                            alignLabel={"right"}
                            size="context-menu"
                            content={
                                <CheckboxComponent
                                    size={CheckboxSize.SM}
                                    checked={companyState.downloadDocuments.length !== 0 && companyState.downloadDocuments.length === companyState.findAllRecivedDocumentsResponse?.total}
                                    indeterminate={companyState.downloadDocuments.length < (companyState.findAllRecivedDocumentsResponse?.total || 0) && companyState.downloadDocuments.length > 0}
                                    style={CheckboxStyle.SQUARE}
                                    onChange={(_, checked) => {
                                        if (checked) {
                                            const total = companyState.findAllRecivedDocumentsResponse?.data.map(doc => { return ({ id: doc.id, name: doc.fileName, objectId: doc.objectId }) })
                                            dispatch(setDownloadDocuments(total))
                                        } else {
                                            dispatch(setDownloadDocuments([]))
                                        }
                                    }}
                                />
                            }
                        />
                        <TableCellComponent label={"Nome del file"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"Descrizione del file"} size="large" cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"tipologia"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={authState.findMeResponse?.periodicity === PeriodicityEnum.Monthly ? "mese/anno" : 'trimestre/anno'} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"ricevuto"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent label={"ultimo download"} cellType={"head"} alignLabel={"left"} />
                        <TableCellComponent cellType={"head"} alignLabel={"right"} size="context-menu" />
                    </TableHeadComponent>
                    <TableBody>
                        {receivedRows}
                    </TableBody>
                </TableComponent>
            </div>
            <AccountingAreaDocumentsModal />
            {
                companyState.findAllRecivedDocumentsResponse !== undefined &&
                companyState.findAllRecivedDocumentsResponse.total > 0 &&
                <PaginationComponent
                    page={companyState.findAllRecivedDocumentsResponse !== undefined ? companyState.findAllRecivedDocumentsResponse.page : 0}
                    count={companyState.findAllRecivedDocumentsResponse !== undefined ? companyState.findAllRecivedDocumentsResponse.totalPage : 0}
                    onChange={(page) => {
                        dispatch(setDocumentReceivedFilterPage(page))
                        dispatch(findAllReceivedDocuments({ companyId: authState.findMeResponse !== undefined ? authState.findMeResponse.id : 0, period: companyState.documentReceivedFilters.period, year: companyState.documentReceivedFilters.year, page: page }))
                    }}
                />
            }
        </div>
    )
}