import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";
import colors from '../utils/index.module.scss';
import { CheckboxSize, CheckboxStyle } from "./dto";
import { CheckboxSizeMap, CheckboxStyleMap } from "./style";

interface Props {
    label?: string,
    supportingText?: string,
    checked?: boolean,
    indeterminate?: boolean,
    size: CheckboxSize,
    style: CheckboxStyle,
    disabled?: boolean,
    onChange?: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void)
}

export function CheckboxComponent(props: Props) {
    const { label } = props
    const { supportingText } = props
    const { size } = props
    const { style } = props
    const { checked } = props
    const { indeterminate } = props
    const { disabled } = props
    const { onChange } = props

    const [focused, setFocused] = useState<boolean>(false);
    const [hover, setHover] = useState<boolean>(false);

    const sizeStyle = CheckboxSizeMap.get(size)
    const checkboxStyle = CheckboxStyleMap.get(JSON.stringify({ size, style }))

    const icon = (
        <div
            style={{
                width: checkboxStyle?.outerDim,
                height: checkboxStyle?.outerDim,
                backgroundColor: focused ? colors.primary50 : '',
                borderRadius: checkboxStyle?.outerRadius,
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center'
            }}>
            <Box
                sx={{
                    zIndex: 2,
                    height: checkboxStyle?.dim,
                    width: checkboxStyle?.dim,
                    borderRadius: checkboxStyle?.borderRadius,
                    border: '1px solid ' + (focused ? colors.primary500 : colors.neutral100),
                    backgroundColor: colors.white,
                    "&:hover": {
                        borderColor: colors.primary500
                    },
                }}
            />
        </div>
    )

    const checkedIcon = (
        <div
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            style={{
                width: checkboxStyle?.outerDim,
                height: checkboxStyle?.outerDim,
                backgroundColor: focused ? colors.primary50 : '',
                borderRadius: checkboxStyle?.outerRadius,
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center'
            }}>
            <Box
                component='div'
                sx={{
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 2,
                    height: checkboxStyle?.dim,
                    width: checkboxStyle?.dim,
                    borderRadius: checkboxStyle?.borderRadius,
                    border: '1px solid ' + colors.primary500,
                    backgroundColor: focused ? colors.primary500 : colors.white,
                    "&:hover": {
                        backgroundColor: colors.primary500
                    },
                }}
            >
                {
                    size === CheckboxSize.MD ? (
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.3333 4L6 11.3333L2.66666 8" stroke={focused || hover ? colors.white : colors.primary500} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    ) : (
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 3L4.5 8.5L2 6" stroke={focused || hover ? colors.white : colors.primary500} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    )
                }
            </Box>
        </div>
    )

    const indeterminateIcon = (
        <div
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            style={{
                width: checkboxStyle?.outerDim,
                height: checkboxStyle?.outerDim,
                backgroundColor: focused ? colors.primary50 : '',
                borderRadius: checkboxStyle?.outerRadius,
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center'
            }}>
            <Box
                component='div'
                sx={{
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 2,
                    height: checkboxStyle?.dim,
                    width: checkboxStyle?.dim,
                    borderRadius: checkboxStyle?.borderRadius,
                    border: '1px solid ' + colors.primary500,
                    backgroundColor: focused ? colors.primary500 : colors.white,
                    "&:hover": {
                        backgroundColor: colors.primary500
                    }
                }}
            >
                {
                    size === CheckboxSize.MD ? (
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.33334 8H12.6667" stroke={focused || hover ? colors.white : colors.primary500} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    ) : (
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.5 6H9.5" stroke={focused || hover ? colors.white : colors.primary500} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    )
                }
            </Box>
        </div>
    )

    return (
        <FormControlLabel
            sx={{
                margin: 0, height: checkboxStyle?.outerDim,
                '& .MuiCheckbox-root': {
                    padding: 0
                },
            }}
            control={
                <Checkbox
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    indeterminate={indeterminate}
                    checked={checked}
                    disabled={disabled}
                    icon={icon}
                    checkedIcon={checkedIcon}
                    indeterminateIcon={indeterminateIcon}
                    onChange={(e, checked) => onChange && onChange(e, checked)}
                />
            }
            label={
                <p style={{ margin: 0, opacity: disabled ? 0.5 : 1 }} className={sizeStyle?.placeholder}>
                    {label}
                    <br />
                    <span style={{ margin: 0 }} className={sizeStyle?.supportingText}>
                        {supportingText}
                    </span>
                </p>
            }
        />
    )
}