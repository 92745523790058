import { Drawer } from "@mui/material";
import { ReactElement, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { AvatarComponent } from "../avatar";
import { AvatarSize, AvatarType } from "../avatar/dto";
import { ArchiveIcon } from "../icons/archive";
import { ArchiveAlertIcon } from "../icons/archiveAlert";
import { BalanceIcon } from "../icons/balance";
import { BalanceAlertIcon } from "../icons/balanceAlert";
import { BarChartIcon } from "../icons/barChart";
import { ChatIcon } from "../icons/chat";
import { ChatAlertIcon } from "../icons/chatAlert";
import { DocumentsIcon } from "../icons/documents";
import { DocumentsAlertIcon } from "../icons/documentsAlert";
import { F24Icon } from "../icons/f24";
import { F24AlertIcon } from "../icons/f24Alert";
import { LifeBuoyIcon } from "../icons/lifeBuoy";
import { UsersIcon } from "../icons/users";
import { UsersAlertIcon } from "../icons/usersAlert";
import { PillComponent } from "../pills";
import { PillsColor, PillsEmphasis, PillsOutline, PillsSize } from "../pills/dto";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import colors from '../utils/index.module.scss';
import { MenuItems } from "./dto";
import { SidebarRow } from "./sidebarRow";

interface Props {
    menuItem?: MenuItems
}

export function SidebarComponent(props: Props) {
    const { menuItem } = props

    const sidebarState = useAppSelector(state => state.sidebar)
    const authState = useAppSelector(state => state.auth)
    const documentsState = useAppSelector(state => state.document)
    const chatState = useAppSelector(state => state.chat)
    const fiscalDocumentState = useAppSelector(state => state.fiscalDocument)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const labelMap = new Map<MenuItems, string>([
        [MenuItems.DASHBOARD, 'Dashboard'],
        [MenuItems.DOCUMENTS, 'Documenti'],
        [MenuItems.CHAT, 'Messaggi'],
        [MenuItems.SUPPORTO, 'Supporto'],
        [MenuItems.EMPLOYEE, 'Gestione dipendenti'],
        [MenuItems.F24, 'F24'],
        [MenuItems.BALANCE, 'Bilancio e dichiarativi altro'],
        [MenuItems.OTHER, 'Altro'],
    ])

    const uriMap = new Map<MenuItems, string>([
        [MenuItems.DASHBOARD, '/'],
        [MenuItems.DOCUMENTS, '/documents'],
        [MenuItems.CHAT, '/messages'],
        [MenuItems.SUPPORTO, '/supp'],
        [MenuItems.F24, '/f24'],
        [MenuItems.BALANCE, '/balance'],
        [MenuItems.EMPLOYEE, '/employee'],
        [MenuItems.OTHER, '/others'],
    ])

    const iconMap = new Map<string, ReactElement>([
        [MenuItems.DASHBOARD, <BarChartIcon colorBase={""} />],
        [MenuItems.DOCUMENTS,
        (
            documentsState.companiesUnseenDocumentsResponse[0] !== undefined &&
            documentsState.companiesUnseenDocumentsResponse[0].unseenRecived > 0 &&
            !sidebarState.open
        ) ?
            <DocumentsAlertIcon colorBase={""} />
            :
            <DocumentsIcon colorBase={""} />],
        [MenuItems.CHAT,
        (

            chatState.groupUnSeenMessagesResponse[0] !== undefined &&
            chatState.groupUnSeenMessagesResponse[0].unseen > 0
            &&
            !sidebarState.open
        ) ?
            <ChatAlertIcon colorBase={""} />
            :
            <ChatIcon colorBase={""} />
        ],
        [MenuItems.SUPPORTO, <LifeBuoyIcon colorBase={""} />],
        [MenuItems.BALANCE,
        (
            fiscalDocumentState.findUnseenFiscalDocumentsResponse !== undefined &&
            fiscalDocumentState.findUnseenFiscalDocumentsResponse.unseenBalance > 0 &&
            !sidebarState.open
        ) ?
            <BalanceAlertIcon colorBase={""} />
            :
            <BalanceIcon colorBase={""} />
        ],
        [MenuItems.F24,
        (
            fiscalDocumentState.findUnseenFiscalDocumentsResponse !== undefined &&
            fiscalDocumentState.findUnseenFiscalDocumentsResponse.unseenF24 > 0 &&
            !sidebarState.open
        ) ?
            <F24AlertIcon colorBase={""} />
            :
            <F24Icon colorBase={""} />
        ],
        [MenuItems.EMPLOYEE,
        (
            fiscalDocumentState.findUnseenFiscalDocumentsResponse !== undefined &&
            fiscalDocumentState.findUnseenFiscalDocumentsResponse.unseenEmployees > 0 &&
            !sidebarState.open
        ) ?
            <UsersAlertIcon colorBase={""} />
            :
            <UsersIcon colorBase={""} />
        ],
        [MenuItems.OTHER,
        (
            fiscalDocumentState.findUnseenFiscalDocumentsResponse !== undefined &&
            fiscalDocumentState.findUnseenFiscalDocumentsResponse.unseenOther > 0 &&
            !sidebarState.open
        ) ?
            <ArchiveAlertIcon colorBase={""} />
            :
            <ArchiveIcon colorBase={""} />
        ]
    ])

    const endIconMap = new Map<string, ReactNode>([
        [MenuItems.DOCUMENTS, documentsState.companiesUnseenDocumentsResponse[0] !== undefined && documentsState.companiesUnseenDocumentsResponse[0].unseenRecived > 0 ? <PillComponent label={documentsState.companiesUnseenDocumentsResponse[0].unseenRecived.toString()} size={PillsSize.XS} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.TRUE} /> : <></>],
        [MenuItems.CHAT, chatState.groupUnSeenMessagesResponse[0] !== undefined && chatState.groupUnSeenMessagesResponse[0].unseen > 0 ? <PillComponent label={chatState.groupUnSeenMessagesResponse[0].unseen.toString()} size={PillsSize.XS} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.TRUE} /> : <></>],
        [MenuItems.BALANCE, fiscalDocumentState.findUnseenFiscalDocumentsResponse !== undefined && fiscalDocumentState.findUnseenFiscalDocumentsResponse.unseenBalance > 0 ? <PillComponent label={fiscalDocumentState.findUnseenFiscalDocumentsResponse.unseenBalance.toString()} size={PillsSize.XS} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.TRUE} /> : <></>],
        [MenuItems.F24, fiscalDocumentState.findUnseenFiscalDocumentsResponse !== undefined && fiscalDocumentState.findUnseenFiscalDocumentsResponse.unseenF24 > 0 ? <PillComponent label={fiscalDocumentState.findUnseenFiscalDocumentsResponse.unseenF24.toString()} size={PillsSize.XS} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.TRUE} /> : <></>],
        [MenuItems.EMPLOYEE, fiscalDocumentState.findUnseenFiscalDocumentsResponse !== undefined && fiscalDocumentState.findUnseenFiscalDocumentsResponse.unseenEmployees > 0 ? <PillComponent label={fiscalDocumentState.findUnseenFiscalDocumentsResponse.unseenEmployees.toString()} size={PillsSize.XS} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.TRUE} /> : <></>],
        [MenuItems.OTHER, fiscalDocumentState.findUnseenFiscalDocumentsResponse !== undefined && fiscalDocumentState.findUnseenFiscalDocumentsResponse.unseenOther > 0 ? <PillComponent label={fiscalDocumentState.findUnseenFiscalDocumentsResponse.unseenOther.toString()} size={PillsSize.XS} color={PillsColor.PRIMARY} emphasis={PillsEmphasis.LOW} outline={PillsOutline.TRUE} /> : <></>]
    ])

    return (
        <Drawer
            variant="permanent"
            open={sidebarState.open}
            PaperProps={{
                sx: {
                    position: 'fixed',
                    flexShrink: 0,
                    width: sidebarState.open ? 280 : 72,
                    backgroundColor: colors.primary50,
                    padding: '24px 16px',
                    display: "flex",
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    transition: '.2s width ease-in-out',
                    border: 0,
                    overflowX: 'hidden',
                    maxHeight: '100vh'
                }
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    height: '100%',
                    width: '100%'
                }}
            >
                <div style={{ marginBottom: '24px' }}>
                    <AvatarComponent type={sidebarState.open ? AvatarType.USER : AvatarType.SINGLE} name={authState.findFinancialAdvisorResponse?.businessName} size={AvatarSize.SM} src={authState.findFinancialAdvisorAvatarResponse} />
                </div>
                <div style={{
                    gap: '4px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    padding: '0px',
                    width: '100%'
                }}>
                    {sidebarState.menuItems.map((item) => (
                        <SidebarRow
                            key={item}
                            open={sidebarState.open}
                            icon={iconMap.get(item)}
                            endIcon={endIconMap.get(item)}
                            label={labelMap.get(item)}
                            active={menuItem === item}
                            onClick={() => {
                                let uri = uriMap.get(item)
                                navigate(uri !== undefined ? uri : '')
                            }}
                        />
                    ))}
                </div>
            </div>
            <div
                style={{
                    gap: '4px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    padding: '0px',
                    width: '100%',
                    marginTop: '24px'
                }}
            >
                <a rel="noreferrer" target="_blank" style={{ textDecoration: 'unset', width: '100%' }} href="mailto:info@iltuocontabile.it?subject=[Il Tuo Contabile] Richiesta supporto&body=Gentile team di supporto, scrivo per segnalarvi che…">
                    <SidebarRow
                        open={sidebarState.open}
                        icon={iconMap.get(MenuItems.SUPPORTO)}
                        label={labelMap.get(MenuItems.SUPPORTO)}
                        active={menuItem === MenuItems.SUPPORTO}
                    />
                </a>
            </div>
        </Drawer >
    )
}