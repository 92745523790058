import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { PromiseStatuses } from "../utils"
import { EmployeeTypology, F24Typology, FinancialStatementsTypology, FindAllFiscalDocumentDTO, FiscalDocumentFilters, FiscalDocumentResponseDTO, FiscalDocumentType, UnseenFiscalDocumentsDTO } from "./dto"
import { NewFiscalDocumentService } from "./service"

interface FiscalDocumentState {
    openFiscalDocumentAreaModal: boolean
    findAllFiscalDocumentBalanceResponse?: FindAllFiscalDocumentDTO
    findAllFiscalDocumentF24Response?: FindAllFiscalDocumentDTO
    findAllFiscalDocumentEmployeeResponse?: FindAllFiscalDocumentDTO
    findAllFiscalDocumentOthersResponse?: FindAllFiscalDocumentDTO
    findAllFiscalDocumentBalanceStatus: PromiseStatuses
    findAllFiscalDocumentF24Status: PromiseStatuses
    findAllFiscalDocumentEmployeeStatus: PromiseStatuses
    findAllFiscalDocumentOthersStatus: PromiseStatuses
    fiscalDocumentBalanceFilters: FiscalDocumentFilters
    fiscalDocumentF24Filters: FiscalDocumentFilters
    fiscalDocumentOthersFilters: FiscalDocumentFilters
    fiscalDocumentEmployeeFilters: FiscalDocumentFilters
    findByIdFiscalDocumentStatus: PromiseStatuses
    findByIdFiscalDocumentResponse?: FiscalDocumentResponseDTO
    findUnseenFiscalDocumentsResponse?: UnseenFiscalDocumentsDTO
    findUnseenFiscalDocumentsStatus: PromiseStatuses
    findByIdFiscalDocumentsStatus: PromiseStatuses
    findByIdFiscalDocumentsResponse?: FiscalDocumentResponseDTO[]
    downloadFiscalDocuments: { id: string, name: string, objectId: string }[]
}

const initialState: FiscalDocumentState = {
    openFiscalDocumentAreaModal: false,
    findAllFiscalDocumentBalanceStatus: 'idle',
    findAllFiscalDocumentF24Status: 'idle',
    findAllFiscalDocumentEmployeeStatus: 'idle',
    fiscalDocumentBalanceFilters: {
        fileName: '',
        typology: '' as FinancialStatementsTypology,
        year: new Date().getFullYear(),
        page: 0,
        itemsPerPage: 15,
        status: '',
        type: FiscalDocumentType.balance,
    },
    fiscalDocumentF24Filters: {
        fileName: '',
        typology: '' as F24Typology,
        page: 0,
        itemsPerPage: 15,
        status: '',
        type: FiscalDocumentType.f24
    },
    fiscalDocumentEmployeeFilters: {
        fileName: '',
        typology: '' as EmployeeTypology,
        year: new Date().getFullYear(),
        page: 0,
        month: '',
        itemsPerPage: 15,
        status: '',
        type: FiscalDocumentType.employees
    },
    fiscalDocumentOthersFilters: {
        fileName: '',
        typology: '' as EmployeeTypology,
        page: 0,
        month: '',
        itemsPerPage: 15,
        status: '',
        type: FiscalDocumentType.other
    },
    findByIdFiscalDocumentStatus: 'idle',
    findAllFiscalDocumentOthersStatus: "idle",
    findUnseenFiscalDocumentsStatus: 'idle',
    downloadFiscalDocuments: [],
    findByIdFiscalDocumentsStatus: "idle"
}

export const findAllFiscalDocumentsBalance = createAsyncThunk(
    'taxArea/findAllFiscalDocumentsBalance',
    async (request: FiscalDocumentFilters, thunkApi): Promise<FindAllFiscalDocumentDTO> => {
        const fiscalDocumentService = NewFiscalDocumentService()

        return fiscalDocumentService.findAllFiscalDocuments(request)
    }
)

export const findAllFiscalDocumentsF24 = createAsyncThunk(
    'taxArea/findAllFiscalDocumentsF24',
    async (request: FiscalDocumentFilters, thunkApi): Promise<FindAllFiscalDocumentDTO> => {
        const fiscalDocumentService = NewFiscalDocumentService()

        return fiscalDocumentService.findAllFiscalDocuments(request)
    }
)

export const findAllFiscalDocumentsEmployee = createAsyncThunk(
    'taxArea/findAllFiscalDocumentsEmployee',
    async (request: FiscalDocumentFilters, thunkApi): Promise<FindAllFiscalDocumentDTO> => {
        const fiscalDocumentService = NewFiscalDocumentService()

        return fiscalDocumentService.findAllFiscalDocuments(request)
    }
)

export const findAllFiscalDocumentsOthers = createAsyncThunk(
    'taxArea/findAllFiscalDocumentsOthers',
    async (request: FiscalDocumentFilters, thunkApi): Promise<FindAllFiscalDocumentDTO> => {
        const fiscalDocumentService = NewFiscalDocumentService()

        return fiscalDocumentService.findAllFiscalDocuments(request)
    }
)

export const findUnseenFiscalDocuments = createAsyncThunk(
    'taxArea/findUnseenFiscalDocuments',
    async (request: string, thunkApi): Promise<UnseenFiscalDocumentsDTO> => {
        const fiscalDocumentService = NewFiscalDocumentService()

        return fiscalDocumentService.unseenFiscalDocuments(request)
    }
)


export const findAllFiscalDocumentsBalanceCounters = createAsyncThunk(
    'taxArea/findAllFiscalDocumentsBalanceCounters',
    async (companyId: string, thunkApi): Promise<FindAllFiscalDocumentDTO> => {
        const fiscalDocumentService = NewFiscalDocumentService()

        return fiscalDocumentService.findAllFiscalDocuments(
            {
                fileName: '',
                typology: '' as FinancialStatementsTypology,
                year: new Date().getFullYear(),
                page: 0,
                itemsPerPage: 0,
                status: '',
                type: FiscalDocumentType.balance,
                companyId: companyId
            }
        )
    }
)

export const findAllFiscalDocumentsF24Counters = createAsyncThunk(
    'taxArea/findAllFiscalDocumentsF24Counters',
    async (companyId: string, thunkApi): Promise<FindAllFiscalDocumentDTO> => {
        const fiscalDocumentService = NewFiscalDocumentService()

        return fiscalDocumentService.findAllFiscalDocuments(
            {
                fileName: '',
                typology: '' as FinancialStatementsTypology,
                page: 0,
                itemsPerPage: 0,
                status: '',
                type: FiscalDocumentType.f24,
                companyId: companyId
            }
        )
    }
)

export const findAllFiscalDocumentsEmployeeCounters = createAsyncThunk(
    'taxArea/findAllFiscalDocumentsEmployeeCounters',
    async (companyId: string, thunkApi): Promise<FindAllFiscalDocumentDTO> => {
        const fiscalDocumentService = NewFiscalDocumentService()

        return fiscalDocumentService.findAllFiscalDocuments(
            {
                fileName: '',
                typology: '' as FinancialStatementsTypology,
                year: new Date().getFullYear(),
                page: 0,
                itemsPerPage: 0,
                status: '',
                type: FiscalDocumentType.employees,
                companyId: companyId
            }
        )
    }
)

export const findByIdFiscalDocument = createAsyncThunk(
    'taxArea/findByIdFiscalDocument',
    async (id: string, thunkApi): Promise<FiscalDocumentResponseDTO> => {
        const fiscalDocumentService = NewFiscalDocumentService()

        return fiscalDocumentService.findByIdFiscalDocument(id)
    }
)

export const findByIdFiscalDocuments = createAsyncThunk(
    'taxArea/findByIdFiscalDocuments',
    async (id: string[], thunkApi): Promise<FiscalDocumentResponseDTO[]> => {
        let promises: Promise<FiscalDocumentResponseDTO>[] = []
        promises = id.map(async (_id) => {
            const fiscalDocumentService = NewFiscalDocumentService()

            return fiscalDocumentService.findByIdFiscalDocument(_id)
        })
        return Promise.all(promises)
    }
)

const fiscalDocumentSlice = createSlice({
    name: 'fiscalDocument/slice',
    initialState,
    reducers: {
        setOpenFiscalDocumentAreaModal: (state, action) => {
            state.openFiscalDocumentAreaModal = action.payload
        },
        setFiscalDocumentBalanceFilterPage: (state, action) => {
            state.fiscalDocumentBalanceFilters.page = action.payload
        },
        setFiscalDocumentOthersFilterPage: (state, action) => {
            state.fiscalDocumentOthersFilters.page = action.payload
        },
        setFiscalDocumentF24FilterPage: (state, action) => {
            state.fiscalDocumentF24Filters.page = action.payload
        },
        setFiscalDocumentEmployeeFilterPage: (state, action) => {
            state.fiscalDocumentEmployeeFilters.page = action.payload
        },
        setYearBalanceFilter: (state, action) => {
            state.fiscalDocumentBalanceFilters.year = action.payload
        },
        setTypologyBalanceFilter: (state, action) => {
            state.fiscalDocumentBalanceFilters.typology = action.payload
        },
        setFileNameBalanceFilter: (state, action) => {
            state.fiscalDocumentBalanceFilters.fileName = action.payload
        },
        setStatusBalanceFilter: (state, action) => {
            state.fiscalDocumentBalanceFilters.status = action.payload
        },
        setStatusF24Filter: (state, action) => {
            state.fiscalDocumentF24Filters.status = action.payload
        },
        setFileNameF24Filter: (state, action) => {
            state.fiscalDocumentF24Filters.fileName = action.payload
        },
        setTypologyF24Filter: (state, action) => {
            state.fiscalDocumentF24Filters.typology = action.payload
        },
        setStatusOthersFilter: (state, action) => {
            state.fiscalDocumentOthersFilters.status = action.payload
        },
        setFileNameOthersFilter: (state, action) => {
            state.fiscalDocumentOthersFilters.fileName = action.payload
        },
        setTypologyOthersFilter: (state, action) => {
            state.fiscalDocumentOthersFilters.typology = action.payload
        },
        setYearEmployeeFilter: (state, action) => {
            state.fiscalDocumentEmployeeFilters.year = action.payload
        },
        setTypologyEmployeeFilter: (state, action) => {
            state.fiscalDocumentEmployeeFilters.typology = action.payload
        },
        setMonthEmployeeFilter: (state, action) => {
            state.fiscalDocumentEmployeeFilters.month = action.payload
        },
        setFileNameEmployeeFilter: (state, action) => {
            state.fiscalDocumentEmployeeFilters.fileName = action.payload
        },
        setStatusEmployeeFilter: (state, action) => {
            state.fiscalDocumentEmployeeFilters.status = action.payload
        },
        setFindByIdFiscalDocumentStatus: (state, action) => {
            state.findByIdFiscalDocumentStatus = action.payload
        },
        setDownloadFiscalDocuments: (state, action) => {
            state.downloadFiscalDocuments = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(findAllFiscalDocumentsBalance.pending, (state) => {
                state.findAllFiscalDocumentBalanceStatus = 'loading'
            })
            .addCase(findAllFiscalDocumentsBalance.fulfilled, (state, action) => {
                state.findAllFiscalDocumentBalanceStatus = 'successfully'
                state.findAllFiscalDocumentBalanceResponse = action.payload
            })
            .addCase(findAllFiscalDocumentsBalance.rejected, (state) => {
                state.findAllFiscalDocumentBalanceStatus = 'failed'
            })
            .addCase(findAllFiscalDocumentsF24.pending, (state) => {
                state.findAllFiscalDocumentF24Status = 'loading'
            })
            .addCase(findAllFiscalDocumentsF24.fulfilled, (state, action) => {
                state.findAllFiscalDocumentF24Status = 'successfully'
                state.findAllFiscalDocumentF24Response = action.payload
            })
            .addCase(findAllFiscalDocumentsF24.rejected, (state) => {
                state.findAllFiscalDocumentF24Status = 'failed'
            })
            .addCase(findAllFiscalDocumentsEmployee.pending, (state) => {
                state.findAllFiscalDocumentEmployeeStatus = 'loading'
            })
            .addCase(findAllFiscalDocumentsEmployee.fulfilled, (state, action) => {
                state.findAllFiscalDocumentEmployeeStatus = 'successfully'
                state.findAllFiscalDocumentEmployeeResponse = action.payload
            })
            .addCase(findAllFiscalDocumentsEmployee.rejected, (state) => {
                state.findAllFiscalDocumentEmployeeStatus = 'failed'
            })
            .addCase(findByIdFiscalDocument.pending, (state) => {
                state.findByIdFiscalDocumentStatus = 'loading'
            })
            .addCase(findByIdFiscalDocument.fulfilled, (state, action) => {
                state.findByIdFiscalDocumentStatus = 'successfully'
                state.findByIdFiscalDocumentResponse = action.payload
            })
            .addCase(findByIdFiscalDocument.rejected, (state) => {
                state.findByIdFiscalDocumentStatus = 'failed'
            })
            .addCase(findAllFiscalDocumentsOthers.pending, (state) => {
                state.findAllFiscalDocumentOthersStatus = 'loading'
            })
            .addCase(findAllFiscalDocumentsOthers.fulfilled, (state, action) => {
                state.findAllFiscalDocumentOthersStatus = 'successfully'
                state.findAllFiscalDocumentOthersResponse = action.payload
            })
            .addCase(findAllFiscalDocumentsOthers.rejected, (state) => {
                state.findAllFiscalDocumentOthersStatus = 'failed'
            })
            .addCase(findUnseenFiscalDocuments.pending, (state) => {
                state.findUnseenFiscalDocumentsStatus = 'loading'
            })
            .addCase(findUnseenFiscalDocuments.fulfilled, (state, action) => {
                state.findUnseenFiscalDocumentsStatus = 'successfully'
                state.findUnseenFiscalDocumentsResponse = action.payload
            })
            .addCase(findUnseenFiscalDocuments.rejected, (state) => {
                state.findUnseenFiscalDocumentsStatus = 'failed'
            })
            .addCase(findByIdFiscalDocuments.pending, (state) => {
                state.findByIdFiscalDocumentsStatus = 'loading'
            })
            .addCase(findByIdFiscalDocuments.fulfilled, (state, action) => {
                state.findByIdFiscalDocumentsStatus = 'successfully'
                state.findByIdFiscalDocumentsResponse = action.payload
            })
            .addCase(findByIdFiscalDocuments.rejected, (state) => {
                state.findByIdFiscalDocumentsStatus = 'failed'
            })
    },
})

export const {
    setOpenFiscalDocumentAreaModal,
    setFiscalDocumentBalanceFilterPage,
    setFiscalDocumentEmployeeFilterPage,
    setFiscalDocumentF24FilterPage,
    setYearBalanceFilter,
    setTypologyBalanceFilter,
    setStatusBalanceFilter,
    setStatusF24Filter,
    setTypologyF24Filter,
    setYearEmployeeFilter,
    setTypologyEmployeeFilter,
    setMonthEmployeeFilter,
    setFileNameBalanceFilter,
    setFileNameEmployeeFilter,
    setFileNameF24Filter,
    setStatusEmployeeFilter,
    setFindByIdFiscalDocumentStatus,
    setFileNameOthersFilter,
    setStatusOthersFilter,
    setTypologyOthersFilter,
    setFiscalDocumentOthersFilterPage,
    setDownloadFiscalDocuments
} = fiscalDocumentSlice.actions

export default fiscalDocumentSlice.reducer