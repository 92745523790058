import { ChatDTO, ChatFilters, CompanyUnseenMessageDTO, GroupChatDTO, GroupChatFilters, MessageFilterDTO, MessageResponse, TopicUnseenInfoDTO, UserInfoDTO } from "./dto";
import { ChatServiceImpl } from "./serviceImpl";

export interface ChatService {
    findChats(filters: ChatFilters): Promise<ChatDTO[]>
    findChatUser(userId: string): Promise<UserInfoDTO>
    findMessages(chatId: number, page: number, itemsPerPage: number): Promise<MessageResponse>
    setChatSeen(chatId: number): Promise<void>
    getCompanyUnseenInfoMessages(topic: string): Promise<CompanyUnseenMessageDTO[]>
    findGroupChats(filters: GroupChatFilters): Promise<GroupChatDTO[]>
    setGroupChatSeen(chatId: number): Promise<void>
    groupUnSeenMessages(topic: string): Promise<TopicUnseenInfoDTO[]>
    findGroupMessages(chatId: number, filter: MessageFilterDTO): Promise<MessageResponse>
}

export function NewChatService(): ChatService {
    return new ChatServiceImpl();
}